import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiButton, EuiSpacer } from '@elastic/eui';
import { OutboundLink } from 'gatsby-plugin-gtag';
import YoutubeVideo from "components/Internet_Access/The_DDNS_Service/YoutubeVideoCardRemote";
import PrimaryBox from "components/Internet_Access/The_DDNS_Service/PrimaryBox";
import DangerBox from "components/Internet_Access/The_DDNS_Service/DangerBox";
import ConfigTable from "components/Internet_Access/The_DDNS_Service/ConfigTable";
import NavButtons from 'components/Internet_Access/NavButtons';
import ForumBox from 'components/Internet_Access/The_DDNS_Service/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "The INSTAR DDNS Service",
  "path": "/Internet_Access/The_DDNS_Service/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Three Easy Steps to access your Camera through the Internet",
  "image": "./IA_SearchThumb_DDNSService.png",
  "social": "/images/Search/IA_SearchThumb_DDNSService.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_IA-DDNS_white.webp",
  "chapter": "Remote Access"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">








    <SEOHelmet title='The INSTAR DDNS Service' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Three Easy Steps to access your Camera through the Internet' image='/images/Search/IA_SearchThumb_DDNSService.png' twitter='/images/Search/IA_SearchThumb_DDNSService.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Fernzugriff/Der_DDNS_Dienst/' locationFR='/fr/Internet_Access/The_DDNS_Service/' crumbLabel="INSTAR DDNS" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "the-instar-ddns-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#the-instar-ddns-service",
        "aria-label": "the instar ddns service permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The INSTAR DDNS Service`}</h1>
    <h2 {...{
      "id": "three-easy-steps-to-access-your-camera-through-the-internet",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#three-easy-steps-to-access-your-camera-through-the-internet",
        "aria-label": "three easy steps to access your camera through the internet permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Three Easy Steps to access your Camera through the Internet`}</h2>
    <p>{`In general, we have to differentiate between two types of connections. You can access your camera's WebUI over its internal IP address - which usually starts with 192.168.x.x or 10.0.x.x. This means that the computer as well as the camera are running in the same network and are most likely connected to the same router. You can then connect to the camera using the private IP address of the camera. The other connection is the external access from outside your network. In this case you need to use your camera's DDNS address ta access the WebUI from anywhere in the world. No matter if you are sitting in the train using a smartphone or if you are on holidays using a laptop. To make this possible a few settings are necessary in the camera as well as in your router. This tutorial shows you step by step how to set up the external connection.`}</p>
    <p>{`Ports are numbered connections, which are used by the computer to connect different types of network traffic. Services such as FTP on Port 21 or HTTP on Port 80 are already determined, many operating systems can even choose the right ports for their respective services on their own. For security reasons, all ports to the internet are closed inside your router by default. This is the reason, why you cannot access your camera through the internet without forwarding a port. To make this possible, there have to be applied some settings. In the following will be explained how to establish the remote access to your camera step-by-step.`}</p>
    <h3 {...{
      "id": "the-basic-concept-of-remote-access",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#the-basic-concept-of-remote-access",
        "aria-label": "the basic concept of remote access permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The basic concept of remote access`}</h3>
    <YoutubeVideo mdxType="YoutubeVideo" />
    <h4 {...{
      "id": "i-static-ip-address",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#i-static-ip-address",
        "aria-label": "i static ip address permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`I. Static IP Address`}</h4>
    <p>{`First of all, connect to your camera in your local network. Then, inside the WebUI, choose `}<strong parentName="p">{`Network / IP Configuration`}</strong>{` (1st Image: `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/IP_Configuration/"
      }}>{`1080p Series`}</a>{` / 2nd Image `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/Network/IP_Configuration/"
      }}>{`720p Series`}</a>{` / 3rd Image: VGA Series, whereupon the following window appears:`}</p>
    <h5 {...{
      "id": "full-hd-kameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#full-hd-kameras",
        "aria-label": "full hd kameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Full HD Kameras`}</h5>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "598px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1da642c471afabf5fe09b42d8cda93eb/0c69d/IP-Configuration_FHD.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "92.17391304347825%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB4ElEQVQ4y6WSS2sTURTH5+uJbjSCLty4cuFCEak7FyrVPoQGFcGFWzfio21WpTYgoh+gGauN4iQpCIJp5r7OvecxMnOTKFOnCP45XC7D/c35n0dy8cmn8w8/nl5LW+30zFqv1U7Ptsv7qfu7J1frcWJlt9VOLzzeu/R0/9yDXrLaGS53RkubZdzbKO/Lm6PF9cHtl9nRuPUiW3ydrXQGj7YOljYGSSGCYDi4eHqnCaE4Vsjy7QewSILE48NJrrQxzlhQ2jjwzEJ/hA8EPsRA4tyGdKQ9ciIi1lrnnIj8NY+IOOcmk0me50qpEAIhClNRSAkbY6y1IYT4tPYXEQEAY4zW2ntPRIjkEUVkmtl731RhzKwqAQDOVMLMrJQyxjBzE++cyyvV4X+pGQC89yEERKRKv2HnXKy5iVdKWWsjU8+stZ73DBGP+jeVmHmalijM4TCT9x4AiKgGz79HCXNReZzads5pra210QLAcUtmPX3+bpmrbscFiJVHhzU4FsmVhFk57A0V8axhTXMWKYjFOnDgkZhCoBAYQ8FYbljxH2qEuZra+36+8Ozr3fXsxvPBq50vw6vX+1cW+pevZTfvsINGOE784Kfvpocf+nl3L9/Pxvn2m/F2d7y1M3n7ThB/ASAn8BDA6vIWAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1da642c471afabf5fe09b42d8cda93eb/e4706/IP-Configuration_FHD.avif 230w", "/en/static/1da642c471afabf5fe09b42d8cda93eb/d1af7/IP-Configuration_FHD.avif 460w", "/en/static/1da642c471afabf5fe09b42d8cda93eb/5fe6f/IP-Configuration_FHD.avif 598w"],
              "sizes": "(max-width: 598px) 100vw, 598px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1da642c471afabf5fe09b42d8cda93eb/a0b58/IP-Configuration_FHD.webp 230w", "/en/static/1da642c471afabf5fe09b42d8cda93eb/bc10c/IP-Configuration_FHD.webp 460w", "/en/static/1da642c471afabf5fe09b42d8cda93eb/83245/IP-Configuration_FHD.webp 598w"],
              "sizes": "(max-width: 598px) 100vw, 598px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1da642c471afabf5fe09b42d8cda93eb/81c8e/IP-Configuration_FHD.png 230w", "/en/static/1da642c471afabf5fe09b42d8cda93eb/08a84/IP-Configuration_FHD.png 460w", "/en/static/1da642c471afabf5fe09b42d8cda93eb/0c69d/IP-Configuration_FHD.png 598w"],
              "sizes": "(max-width: 598px) 100vw, 598px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1da642c471afabf5fe09b42d8cda93eb/0c69d/IP-Configuration_FHD.png",
              "alt": "Der INSTAR DDNS Dienst",
              "title": "Der INSTAR DDNS Dienst",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h5 {...{
      "id": "hd-kameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#hd-kameras",
        "aria-label": "hd kameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HD Kameras`}</h5>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "472px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f6cdb97905855c49156a127781a997e2/3c5de/IP-Configuration_HD.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "137.3913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAbCAYAAAB836/YAAAACXBIWXMAAAsTAAALEwEAmpwYAAACy0lEQVRIx5VV2VrqYAzk/d9ErnwAWUsBi9gWKHSHFrR0YUdEb8YvwVY9gvRczMfyp/lnkklamPo+qtUqWs0mBEFAvVZHR7pHuVxBpVKBrChYr9dYLBYXsVwukSQJbm9vUZhOp+j3+1BVFf1eD4+PMjRtAFXtQZZljEYjBEGAp6cnPD8/nwWdhWGI2WyGwnw+5wdMw4DveXAcF6ZpwrIs/qTz1WrFLP7CZrOB4zgo8O2zGUzLgm1ZiOMY+/2eAyiQJCV/yOXzJOGyuK6LAlE1TQPl0h1KdyUoigJVVZjxNVYpKGkURRiPxyfJJHU0HELXDViWzTUktmlwnoZkCYkhJRPFBoSGiG63izhOslvzgHJQfJaQ5DUEAe1WG0K9jkZD5FupjlSba6CmkdJMsuvYkOVH7up2u+Wg9OY8oBw/GFqmiWq5DFFssv96vV7uhqR1pjwZw8lkAkWW2eDUFDLotWb8axtCxnCoaajXBR4/6jBNT96GpKXJEp4YjjGeeLAtG7ZlwjBMHA6H3Hh5efnJ0NB11Ko1CEIDtuOwZCpDHniex4myGqZNEUUR290e7+/vjOPxiMPhFa+v10EsvyRzQgOSJGEw0KCPhtA0LatjXtv8kEwMy6USNG3IyW3bRhDk2zKpbWi6OGEwn/M+6z48IAwj7HY7NnaeOf5umyj8nBRakPTH6ZY4k0nf8yBJYszDGGGy/rINWUW6l9DpdNCRTp+KokKRFXZAOq/nsNus4AZrtJ09pp77tW1qtRra7TbGkwmzpMVLoAuvgeKWi+8+NHR+fxzf3tik/wva8FEcfzYlCBDHEdJankMq79I51ZKamNWQ3E5vN13Xf4E8KX3Wdchb/XwM5aDJYcmDwYDfya1WC81mMwP9pv+LNzcoFot/xlAO3/dPklPfXeok1Yhw6ZyepcXMkmlL05gR5d/wMynpIiAW52JpodA+/QCM2r72o7sFjwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f6cdb97905855c49156a127781a997e2/e4706/IP-Configuration_HD.avif 230w", "/en/static/f6cdb97905855c49156a127781a997e2/d1af7/IP-Configuration_HD.avif 460w", "/en/static/f6cdb97905855c49156a127781a997e2/05b67/IP-Configuration_HD.avif 472w"],
              "sizes": "(max-width: 472px) 100vw, 472px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f6cdb97905855c49156a127781a997e2/a0b58/IP-Configuration_HD.webp 230w", "/en/static/f6cdb97905855c49156a127781a997e2/bc10c/IP-Configuration_HD.webp 460w", "/en/static/f6cdb97905855c49156a127781a997e2/ef93e/IP-Configuration_HD.webp 472w"],
              "sizes": "(max-width: 472px) 100vw, 472px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f6cdb97905855c49156a127781a997e2/81c8e/IP-Configuration_HD.png 230w", "/en/static/f6cdb97905855c49156a127781a997e2/08a84/IP-Configuration_HD.png 460w", "/en/static/f6cdb97905855c49156a127781a997e2/3c5de/IP-Configuration_HD.png 472w"],
              "sizes": "(max-width: 472px) 100vw, 472px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f6cdb97905855c49156a127781a997e2/3c5de/IP-Configuration_HD.png",
              "alt": "Der INSTAR DDNS Dienst",
              "title": "Der INSTAR DDNS Dienst",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h5 {...{
      "id": "vga-kameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#vga-kameras",
        "aria-label": "vga kameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`VGA Kameras`}</h5>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "620px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/dd84974c001e7421e8c66a53b72a5adb/2a195/IP-Configuration_VGA.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "32.608695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAABEElEQVQoz42RzW6DMBCEef934RB6SEOigNpCmwNgA40KEhD+f4JA8AJT7VaqeqBqD5/Wa8vjnbEy3u/o+54ZiGFA13WYpglpkuDydoFt23h5fkKSpFjXlc/med5EedzvcdR1GOcz9MMBpmlyTyJRHKMoCqRpiizL0DQN2rZFXde/omi7HQzDQBiG8H2fCYIAUkqucRTher2y4LIsGMcRZVmiqqpNlAdNw+l4guM4EELA8zwI4cF1Xe7fwxBSCOS3G1uiaPI858m3UFRVhWVZPBGJfQkKFpTSR+j78FwPdd1ydn8KapoG2379tkmQbRKldRR9cG403b8sUzakTK/+hC6xXSn4M6jGccwTUvi0t8UnGs/znmo55C8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dd84974c001e7421e8c66a53b72a5adb/e4706/IP-Configuration_VGA.avif 230w", "/en/static/dd84974c001e7421e8c66a53b72a5adb/d1af7/IP-Configuration_VGA.avif 460w", "/en/static/dd84974c001e7421e8c66a53b72a5adb/e25ad/IP-Configuration_VGA.avif 620w"],
              "sizes": "(max-width: 620px) 100vw, 620px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/dd84974c001e7421e8c66a53b72a5adb/a0b58/IP-Configuration_VGA.webp 230w", "/en/static/dd84974c001e7421e8c66a53b72a5adb/bc10c/IP-Configuration_VGA.webp 460w", "/en/static/dd84974c001e7421e8c66a53b72a5adb/cf77e/IP-Configuration_VGA.webp 620w"],
              "sizes": "(max-width: 620px) 100vw, 620px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dd84974c001e7421e8c66a53b72a5adb/81c8e/IP-Configuration_VGA.png 230w", "/en/static/dd84974c001e7421e8c66a53b72a5adb/08a84/IP-Configuration_VGA.png 460w", "/en/static/dd84974c001e7421e8c66a53b72a5adb/2a195/IP-Configuration_VGA.png 620w"],
              "sizes": "(max-width: 620px) 100vw, 620px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/dd84974c001e7421e8c66a53b72a5adb/2a195/IP-Configuration_VGA.png",
              "alt": "Der INSTAR DDNS Dienst",
              "title": "Der INSTAR DDNS Dienst",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ConfigTable mdxType="ConfigTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Then click on submit to apply the settings.`}</p>
    <Link to="/Software/Mobile_Access_Over_HTTPS/" mdxType="Link"><EuiButton color="danger" fill mdxType="EuiButton">Kein Audio in der App bei Ihrer 1080p Kamera?</EuiButton></Link>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Now navigate to `}<strong parentName="p">{`System - Info`}</strong>{` (`}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/System/Info/"
      }}>{`1080p Serie`}</a>{` / `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/System/Info/"
      }}>{`720p Serie`}</a>{`). There you can see the current DDNS-Status. When the status is "successful", your camera registered on our server and `}<strong parentName="p">{`you can continue with Step 2`}</strong>{`, where we will set up your router for the remote access. (Otherwise follow the (red) Troubleshooting below.)`}</p>
    <h4 {...{
      "id": "ii-forward-a-port-inside-your-router",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#ii-forward-a-port-inside-your-router",
        "aria-label": "ii forward a port inside your router permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`II. Forward a Port inside your Router`}</h4>
    <Link to="/Internet_Access/Port_Forwarding/" mdxType="Link"><EuiButton fill mdxType="EuiButton">Continue Reading ...</EuiButton></Link>
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <DangerBox mdxType="DangerBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      